.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.modalTrip {
  max-height: 80vh;
  overflow-y: scroll;
  padding: 8px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modalTrip::-webkit-scrollbar {
  display: none;
}

.modal {
  max-height: 90%;
  max-width: 90%;
  overflow: hidden;
  border-radius: 6px;
  padding: 32px;
  padding-top: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
}

.modalLightBox {
  max-height: 90%;
  min-width: 90%;
  overflow: hidden;
  border-radius: 6px;
  padding: 0px;
  padding-top: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalSize {
  max-height: 90%;
  overflow: hidden;
  border-radius: 6px;
  padding: 32px;
  padding-top: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1140px;
}

@media screen and (max-width: 1180px) {
  .modalSize {
    width: 800px;
  }
}

@media screen and (max-width: 840px) {
  .modalSize {
    padding-left: 8px;
    padding-right: 8px;
    width: 500px;
  }
}

@media screen and (max-width: 540px) {
  .modalSize {
    width: 98vw;
  }
}

.imgCounter {
  position: absolute;
  bottom: 4%;
  left: 4%;
  padding: 6px;
  background: white;
  border-radius: 6px;
}

.imgArrow {
  position: absolute;
  font-size: 32px;
  transform: translateY(-50%);
  top: 50%;
  color: white;
  opacity: 0.4;
  transition: 0.2s;
}

.imgArrow:hover {
  opacity: 1;
}

.imgArrowLeft {
  left: 18px;
}

.imgArrowRight {
  right: 18px;
}