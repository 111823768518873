::-webkit-scrollbar {
  /* width */
  width: 10px;
}

::-webkit-scrollbar-track {
  /* Track */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  /* Handle */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  /* Handle on hover */
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track-piece {
  /* not handle on */
  background: white;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-top-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.border-radius-left-12 {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.border-radius-right-12 {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.fi,
.fib {
  display: inline-block;
  font-size: 16px;
  min-width: 21px;
  min-height: 16px;
}

.dsgLink {
  font-weight: bold;
	word-break: break-word;
}

.dsgMention {
  font-weight: bold;
  display: inline-block;
  margin-right: 24px;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  height: 22px;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efefef;
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #FFC425;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #FFC425;
} */
.hiddenScroll {
  scrollbar-width: thin; /* Adjust the width of the scrollbar */
}

/* For WebKit browsers (e.g., Chrome, Safari) */
.hiddenScroll::-webkit-scrollbar {
  width: 4px; /* Adjust the width of the scrollbar */
}

.hiddenScroll::-webkit-scrollbar {
overflow:auto
}

.hiddenScroll {
overflow:auto
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.overlay {
  border-radius: 6px;
  position: absolute;
  top: 8px;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  right: 0px;
}

.centerItem {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.favorites {
	background-color: black;
	border-radius: 50%;
	font-size: 8px;
	color: white;
	padding: 0px 4px 0px 4px;
	position: absolute;

}


.favMobile {
	left: 28px;
	bottom: 12px;
}

.favPC {
	left: 12px;
	bottom: 1px;
}


@media screen and (max-width: 600px) {
  .left-right-sx {
    display: inline-flex;
    justify-content: space-between;
  }
}

.imgSizeCarr {
  height: 123 !important;
}

@media screen and (max-width: 1020px) {
  .imgSizeCarr {
    height: 120px !important;
  }
}

@media screen and (max-width: 740px) {
  .imgSizeCarr {
    height: 150px !important;
  }
}

@media screen and (max-width: 420px) {
  .imgSizeCarr {
    height: 200px !important;
  }
}

@media screen and (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }
}

ul,
ol {
  padding-left: 30px;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.und-wh:after {
  background-color: black;
}

.und-bl:after {
  background-color: white;
}
.und-wh-top:after {
	background-color: #a5a5a5;
}


.und-bl-top:after {
	background-color:#737373;
}
.hover-underline-animation:hover:after {
  text-decoration: none;
  transform: scaleX(1);
  transform-origin: bottom left;
}
