video::-webkit-media-text-track-display
{
   /* transform: translateY(0em) !important; */
   font-size: 14px
}
@media screen and (max-width: 768px) {
  video::-webkit-media-text-track-display {
    font-size: 8px;
  }
}

video:-webkit-full-screen::cue {
    font-size: 24px;
}

video:-moz-full-screen::cue {
    font-size: 24px;
}

video:fullscreen::cue {
    font-size: 24px;
}


.lazyload-wrapper {
	height: 100%;
}
.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
display: none;
}

.vjs-big-vr-play-button {
background-repeat: no-repeat;
width: 1.5em !important;
height: 1.5em !important;
margin-left: -0.7em !important
}

.custom-iphone-fullscreen {
  /* Add styles to adjust the layout of the element */
  width: 90vw;
  height: 90vh;
  /* Add other styles as needed */
}

.fullscreen-overlay {
  /* Styles for the overlay to cover the screen */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
	overflow: hidden;
}

.fullscreen-overlay > div {
	height: 100% !important;
}

.article-iframe-wrapper {
  width: 100%;
  max-width: 100%;
	border-radius: 12px;
}
.article-iframe-wrapper iframe {
  width: 100%;
  border: none;
   height: auto;
    min-height: 400px;
	border-radius: 12px;
}

@media (min-width: 1024px) {
  .article-iframe-wrapper iframe {
    /* Apply height: auto and a minimum height of 400px for desktop screens */
    height: auto;
    min-height: 500px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: white !important;
    print-color-adjust: white !important;
  }
  * {
    visibility: hidden;

    background-color: white !important;
  }

  .printable {
    visibility: visible;
    background: none;
    background-color: white;
    text-shadow: none;
  }

  #printable {
    visibility: visible;
    display: block;
    background: none;
    background-color: white;
    text-shadow: none;
  }

  #print-image {
    visibility: visible;
    display: block !important;
    color: black !important;
  }

  .noprintable {
    visibility: hidden;
    display: 'none';
  }

  #noprintable.noprintable * {
    visibility: hidden;
    display: none;
  }
  #noprintable.noprintable {
    visibility: hidden;
    display: none;
  }

  .noprintable * {
    visibility: hidden;
    display: 'none';
  }

  #print-metadata {
    visibility: visible;
    display: block;
    margin-top: 400px;
    line-height: 1.8;
    color: black !important;
  }

  .print-title {
    display: block !important;
    color: black !important;
    visibility: visible;
  }

  #print-image * {
    display: block !important;
    visibility: visible;
    height: 400px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .print-title * {
    display: block !important;
    visibility: visible;
    color: black !important;
  }

  .printable * {
    visibility: visible;
    color: black !important;
    background-color: white;
    text-shadow: none !important;
  }

  #print-metadata * {
    visibility: visible;
    line-height: 1.8;
    color: black !important;
  }
}

/* @media screen {
   .print-metadata {
       display: none;
   }
} */
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
a:focus {
  text-decoration: none !important;
}
a:active {
  text-decoration: none !important;
}

[data-testid='fullscreen-controls'] > button {
  background-color: rgba(0, 0, 0, 0.5);
}
[data-testid='fullscreen-controls'] > button > svg {
  fill: white;
}


[data-testid='drawer']::-webkit-scrollbar {
  width: 7px;
}

#content-page {
  scroll-margin-top: 10rem;
}
.current::after {
    content: "";
    top: 0px;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    outline: #FFC425 solid 2px !important;
}

[data-testid='drawer'] > div {
	    align-items: center;
    grid-auto-flow: row;
    /* grid-auto-rows: 100px; play with this to change height of the children, 50% will fill half */
    grid-template-columns: unset !important; /* do not set template columns and rows */

    grid-template-rows: unset;
    overflow-y: auto;
  scrollbar-width: thin; /* Adjust the width of the scrollbar */
}

/* For WebKit browsers (e.g., Chrome, Safari) */
[data-testid='drawer'] > div::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

button[title~='zoom'] {
  background-color: rgba(0, 0, 0, 0.5);
}
button[title~='zoom'] > svg {
  fill: white;
}


.react-iiif-viewer > div > button > img {
  /* display: none !important; */
  width: 0.7rem;
}

.react-iiif-viewer > div > div > button > img {
  /* display: none !important; */
  width: 0.9rem;
}

.clover-header {
  display: none !important;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./fonts/Lato-Semibold.ttf');
  font-weight: 500;
  font-display: swap;
  /* letter-spacing: 0.85px; */
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./fonts/Lato-Bold.ttf');
  font-weight: 700;
  font-display: swap;
  /* letter-spacing: 0.85px; */
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./fonts/Lato-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  /* letter-spacing: 0.85px; */
}
body {
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif; /* Sonar: Unexpected missing generic font family */
  font-family: 'Lato' !important;
  /* letter-spacing: 0.65px !important; */
}

[class*='MuiAutocomplete-root'] .MuiOutlinedInput-root {
  background-color: #f0f0f0;
  color: black !important;
  /* max-height: 48px !important; */
}
[class*='MuiAutocomplete-root'] .MuiOutlinedInput-root > [class*='MuiAutocomplete-root'] .MuiAutocomplete-hasPopupIcon {
  height: auto !important;
}
/* [class*="MuiAutocomplete-root"] .MuiOutlinedInput-root > input{
margin-top: -5px !important;
} */

::placeholder {
  color: #020202 !important;
}

[class*='MuiAutocomplete-root'] .MuiOutlinedInput-root :focus {
  background-color: #dedede;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #dedede !important;
}

[class*='MuiFormLabel-root-MuiInputLabel-root'] {
  color: black;
}

[class*='MuiButtonBase-root-MuiChip-root'] {
  background-color: #020202;
}

.MuiChip-label {
  /* color: #dedede !important; */
  font-weight: 800 !important;
}


[class*='MuiAutocomplete-root'] .MuiAutocomplete-tag {
  color: black;
  height: 22px;
  /* margin-top:-1px !important; */
}

.MuiAutocomplete-listbox {
  background-color: #c4c4c4 !important;
  color: black !important;
}

[class*='MuiAutocomplete-popupIndicator'] > [class*='MuiSvgIcon-root'] {
  fill: black !important;
}

[class*='MuiAutocomplete-clearIndicator'] > [class*='MuiSvgIcon-root'] {
  fill: black !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-hasClearIcon [class*='MuiAutocomplete-root'] .MuiOutlinedInput-root {
  padding-right: 56px !important;
}

[class*='MuiAutocomplete-root'] .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
		print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .hidden-print {
    display: none !important;
  }
}
