.none {
    display: none;
}

.banner {
    width: 100%;
    position: fixed;
    z-index: 999999999999999;
    bottom: 0;
    padding: 20px 80px 20px;
    box-sizing: border-box;
    animation: enter 0.5s ease-out;
		text-align: left;
}

.banner.hide {
    animation: leave 0.5s ease-in;
}

.background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: -1;
    /* opacity: 0.9; */
}

.actions {
    display: flex;
    /* justify-content: space-around; */
    /* max-width: 280px; */
		padding-top: 15px;
    margin: 0 auto;
}

.banner .button {
    font-size: 14px;
}

@keyframes enter {
    from {transform: translateY(100%)}
    to {transform: translateY(0%)}
}
@keyframes leave {
    from {transform: translateY(0%)}
    to {transform: translateY(100%)}
}